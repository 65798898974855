import { createStore } from "vuex";

export default createStore({
  state: {
    source: null, //登录来源登录结束后返回使用
  },
  mutations: {
    SETSOURCE(state, source) {
      state.source = source;
    },
  },
  actions: {},
  modules: {},
});
