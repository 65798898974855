<template>
  <div class="div_home flex">
    <div class="left">
      <img
        class="img_logo"
        src="@/assets/image/home/logo.svg"
        alt=""
        @click="goBackHome()"
      />
      <h1 class="cp_us" @click="goBackHome()">
        多模态笔记学习卡<br />
        学习方式的新选择
      </h1>
      <img class="img_man" src="@/assets/image/home/img_man.png" alt="" />
      <p>Keymemo design<br />Innovation.</p>
    </div>
    <div class="right">
      <div class="div_view_card">
        <router-view></router-view>
      </div>
      <span class="hover" @click="goBackHome()">返回首页</span>
    </div>
  </div>
</template>

<script setup>
function goBackHome() {
  localStorage.clear();
  window.location.replace(process.env.VUE_APP_REDIRECT);
}
</script>

<style lang='scss' scoped>
.div_home {
  width: 100%;
  height: 100%;
  background-color: #f8f9fa;
  .left {
    width: 500px;
    height: 100%;
    background-color: white;
    padding: 20px 0px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    .img_logo {
      height: 40px;
      padding-left: 30px;
      cursor: pointer;
    }
    .img_man {
      width: 80%;
      margin: 0px auto;
    }
    > h1 {
      font-size: 30px;
      padding-left: 30px;
      color: #000000;
    }
    > p {
      padding-left: 30px;
      font-size: 14px;
      font-weight: 500;
      color: #999999;
    }
  }
  .right {
    width: calc(100% - 500px);
    height: 100%;
    position: relative;
    background-color: #f8f9fa;
    display: flex;
    justify-content: center;
    align-items: center;
    .div_view_card {
      width: 480px;
      background: #ffffff;
      box-shadow: 0px 10px 60px 1px rgba(66, 86, 105, 0.1);
      border-radius: 20px 20px 20px 20px;
    }
    .hover {
      position: absolute;
      top: 60px;
      right: 65px;
      font-size: 16px;
      font-weight: 500;
      color: #425669;
    }
  }
}
</style>
