import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import "element-plus/dist/index.css";
import ElementPlus from "element-plus";

import locale from "element-plus/lib/locale/lang/zh-cn";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";

import "./assets/css/global.css";
import "./assets/css/iconfont.css";
import "animate.css";

const app = createApp(App);
for (const [name, comp] of Object.entries(ElementPlusIconsVue)) {
  app.component(name, comp);
}
app
  .use(store)
  .use(router)
  .use(ElementPlus, {
    locale,
  })
  .mount("#app");
