import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import store from "../store/index.js";
const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/login",
        name: "login",
        meta: { title: "账号登录" },
        component: () => import("@/views/login.vue"),
      },
      {
        path: "/wx-login",
        name: "wxLogin",
        meta: { title: "微信登录" },
        component: () => import("@/views/wxLogin.vue"),
      },
      {
        path: "/forget-passworld",
        name: "forgetPassworld",
        meta: { title: "忘记密码" },
        component: () => import("@/views/other/forgetPassworld.vue"),
      },
      {
        path: "/set-message",
        name: "setMessage",
        meta: { title: "用户信息设置" },
        component: () => import("@/views/other/setMessage.vue"),
      },
      {
        path: "/set-phone",
        name: "setPhone",
        meta: { title: "绑定手机号" },
        component: () => import("@/views/other/setPhone.vue"),
      },
      {
        path: "/information-collection",
        name: "informationCollection",
        meta: { title: "用户信息采集" },
        component: () => import("@/views/other/informationCollection.vue"),
      },
      { path: "/", redirect: "/wx-login" },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  //获取登录来源页面以便登录成功后跳转使用
  if (to.query.source) {
    store.commit("SETSOURCE", decodeURIComponent(to.query.source));
    next();
  } else {
    next();
  }
});

export default router;
