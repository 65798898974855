<template>
  <router-view />
</template>

<script setup>
</script>

<style lang="scss">
#app {
  width: 100vw;
  min-width: 1280px;
  height: 100vh;
  overflow: hidden;
  font-family: PingFang SC-Semibold, PingFang SC;
}
</style>
